import type { FacetSchema } from '~/utils/types/schema'
import { FacetSchemaItemType } from '~/utils/types/schema/enums'

export default {
    [FacetSchemaItemType.FEATURED_OPTIONS]: {
        'Android Auto': {
            file: 'android-auto',
            classes: '',
        },
        'Apple CarPlay': {
            file: 'apple-carplay',
            classes: '',
        },
    },
    [FacetSchemaItemType.COLORS]: {
        black: {
            name: 'Black',
            hex: '#000000',
        },
        white: {
            name: 'White',
            hex: '#FFFFFF',
        },
    },
} as FacetSchema
