import type { FunctionalComponent } from 'vue'
import { LeadModalComponent } from '~/utils/types/leadModal'
import type { VehicleIncentive } from '~/utils/types/inventory'

export default {
    [LeadModalComponent.ASK_QUESTION]: {
        source: 'ask-question',
        defaults: {
            ctaButtonText: 'Ask a Question',
            title: 'Ask a Question',
            description:
                'Our vehicles are always moving quick, this quick little form sends one of our sales reps out on the lot to verify this [vehicle.make] is still available for purchase.',
            formButtonText: 'Ask Question',
            ascText: 'Ask a Question',
            ascColor: '',
        },
    },
    [LeadModalComponent.CAPITAL_ONE]: {
        source: '',
        defaults: {
            ctaButtonText: 'Explore Financing',
            title: '',
            description: '',
            formButtonText: '',
            ascText: 'Explore Financing',
            ascColor: '',
        },
    },
    [LeadModalComponent.CONFIRM_AVAILABILITY]: {
        source: 'confirm-availability',
        defaults: {
            ctaButtonText: 'Confirm Availability',
            title: 'Confirm Availability',
            description:
                'Our vehicles are always moving quick, this quick little form sends one of our sales reps out on the lot to verify this [vehicle.make] is still available for purchase.',
            formButtonText: 'Confirm Availability',
            ascText: 'Confirm Availability',
            ascColor: '',
        },
    },
    [LeadModalComponent.DEPOSIT]: {
        source: '',
        defaults: {
            ctaButtonText: 'Reserve Your Vehicle',
            title: '',
            description: '',
            formButtonText: '',
            ascText: 'Reserve Your Vehicle',
            ascColor: '',
        },
    },
    [LeadModalComponent.FINANCING]: {
        source: '',
        defaults: {
            ctaButtonText: 'Apply for Financing',
            title: '',
            description: '',
            formButtonText: '',
            ascText: 'Apply for Financing',
            ascColor: '',
        },
    },
    [LeadModalComponent.GET_E_PRICE]: {
        source: 'get-e-price',
        defaults: {
            ctaButtonText: 'Get E-Price',
            title: 'Get E-Price',
            description:
                'Our vehicles are always moving quick, this quick little form sends one of our sales reps out on the lot to verify this [vehicle.make] is still available for purchase.',
            formButtonText: 'Get E-Price',
            ascText: 'Get E-Price',
            ascColor: '',
        },
    },
    [LeadModalComponent.PHOTO_REQUEST]: {
        source: 'photo-request',
        defaults: {
            ctaButtonText: 'Request Photos',
            title: 'Request Photos',
            description:
                'Our vehicles are always moving quick, this quick little form sends one of our sales reps out on the lot to verify this [vehicle.make] is still available for purchase.',
            formButtonText: 'Request Photos',
            ascText: 'Request Photos',
            ascColor: '',
        },
    },
    [LeadModalComponent.SCHEDULE_APPOINTMENT]: {
        source: 'schedule-appointment',
        defaults: {
            ctaButtonText: 'Schedule a Test Drive',
            title: 'Schedule Test Drive',
            description:
                'Ready to take this vehicle for ride? Schedule a test drive at the dealership to take it for a drive!',
            formButtonText: 'Schedule Test Drive',
            ascText: 'Schedule a Test Drive',
            ascColor: '',
        },
    },
    [LeadModalComponent.SEND_TO_PHONE]: {
        source: 'to-phone',
        defaults: {
            ctaButtonText: 'Send Vehicle to Your Phone',
            title: 'Send This Vehicle to Your Phone',
            description:
                'Our vehicles are always moving quick, this quick little form sends one of our sales reps out on the lot to verify this [vehicle.make] is still available for purchase.',
            formButtonText: 'Send to Your Phone',
            ascText: 'Send Vehicle to Your Phone',
            ascColor: '',
        },
    },
    [LeadModalComponent.VEHICLE_INCENTIVE]: {
        source: 'unlock-incentive',
        defaults: {
            ctaButtonText: '',
            title: '',
            description: '',
            formButtonText: '',
            ascText: '',
            ascColor: '',
        },
    },
} as Record<LeadModalComponent, LeadComponentTemplateData>

export interface LeadComponentTemplateData {
    source: string
    defaults: LeadComponentDataDefaults
    icon?: FunctionalComponent
}

export interface LeadComponentDataDefaults {
    ctaButtonText: string
    title: string
    description: string
    formButtonText: string
    ascText: string
    ascColor: string
}

export interface LeadButtonData {
    component: LeadModalComponent
    ctaButtonText: string
    source: string
    sold: boolean
    title: string
    description: string
    formButtonText: string
    asc: Record<'text' | 'color', string>
    incentive: VehicleIncentive | null
    icon: FunctionalComponent | null
}

export enum CTAButtonComponentType {
    SRP = 'SRP',
    VDP = 'VDP',
}
