<template>
    <div class="w-full relative fade-r-10">
        <div
            class="relative w-full flex gap-x-1.5 snap-x overflow-x-auto scrollbar-hidden pr-8"
            dir="ltr"
        >
            <component
                :is="tab.component"
                v-for="(tab, index) in tabs"
                :key="index"
                :link="tab.link"
                :content="tab.content"
                :icon="tab.icon"
                :custom-classes="tab.customClasses"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import type { PropType } from 'vue'
import { DocumentTextIcon } from '@heroicons/vue/16/solid'
import type { Vehicle } from '~/utils/types/inventory'
import Link from '~/components/SearchResultsPage/Vehicle/GridVehicle/GridVehicleScrollbar/Components/Link.vue'
import Option from '~/components/SearchResultsPage/Vehicle/GridVehicle/GridVehicleScrollbar/Components/Option.vue'
import Tab from '~/components/SearchResultsPage/Vehicle/GridVehicle/GridVehicleScrollbar/Components/Tab.vue'
import type { Facet } from '~/utils/types/inventoryFilter'

const filterStore = useInventoryFilterStore()

const props = defineProps({
    vehicle: {
        type: Object as PropType<Vehicle>,
        required: true,
    },
})

const windowSticker = computed(
    () => props.vehicle.vehicle_make_sticker_url ?? null,
)

const modifications = computed(() => props.vehicle.modifications ?? {})

const attributesSorted = computed<string[]>(() => {
    const attributes: Record<string, string>[] =
        props.vehicle.attribute_brands ?? []

    if (attributes.length === 0) {
        return []
    }

    const sortArr = ['lift-kit', 'wheels', 'tires']
    const returnArr = []

    for (let i = 0; i < sortArr.length; i++) {
        const sort = sortArr[i]

        const attributeSearch: Record<string, string> | null =
            attributes.find((attribute: any) => attribute.key === sort) ?? null

        if (attributeSearch) {
            returnArr.push(attributeSearch.value)
        }
    }

    return returnArr
})

const featuredOptions = computed<string[]>(
    () =>
        props.vehicle.featured_options_highlights ??
        props.vehicle.featured_options_sorted ??
        [],
)

const currentFilterFeaturedOptions = computed<string[]>(() => {
    const facetOptions = !props.vehicle.featured_options_highlights
        ? (filterStore.facets['featured_options.lvl1'] ?? [])
        : []

    return facetOptions
        .filter((facet: Facet) => facet.checked)
        .map((facet: Facet) => facet.label ?? '')
        .sort()
})

const selectedFromFiltersFeaturedOptions = computed(() =>
    featuredOptions.value.filter((option: string) =>
        currentFilterFeaturedOptions.value.includes(option),
    ),
)

const featuredOptionsFormatted = computed(() => {
    return {
        selected: selectedFromFiltersFeaturedOptions.value,
        notSelected: featuredOptions.value
            .filter(
                (option: string) =>
                    !selectedFromFiltersFeaturedOptions.value.includes(option),
            )
            .slice(0, 10),
    }
})

const tabs = computed<VehicleScrollbarTab[]>(() => {
    const returnArr: VehicleScrollbarTab[] = []

    if (props.vehicle.condition) {
        returnArr.push({
            component: Tab,
            content: props.vehicle.condition,
            customClasses: '!bg-blue-50 !text-blue-700',
        })
    }

    featuredOptionsFormatted.value.selected.forEach((option: string) =>
        returnArr.push({
            component: Option,
            content: option,
        }),
    )

    attributesSorted.value.forEach((attribute: string) =>
        returnArr.push({
            component: Tab,
            content: attribute,
        }),
    )

    if (windowSticker.value) {
        returnArr.push({
            component: Link,
            link: windowSticker.value,
            content: 'Window Sticker',
            icon: DocumentTextIcon,
        })
    }

    if (modifications.value.lifted || modifications.value.lowered) {
        returnArr.push({
            component: Tab,
            content: modifications.value.lifted ? 'Lifted' : 'Lowered',
        })
    }

    featuredOptionsFormatted.value.notSelected.forEach((option: string) =>
        returnArr.push({
            component: Option,
            content: option,
        }),
    )

    return returnArr
})

interface VehicleScrollbarTab {
    component: any
    content: any
    icon?: any
    link?: string | null
    customClasses?: string
}
</script>
