import type { Vehicle } from '~/utils/types/inventory'
import type { Location } from '~/utils/types/location'

export enum LeadModalComponent {
    ASK_QUESTION = 'AskQuestion',
    CAPITAL_ONE = 'CapitalOne',
    CONFIRM_AVAILABILITY = 'ConfirmAvailability',
    DEPOSIT = 'Deposit',
    FINANCING = 'Financing',
    GET_E_PRICE = 'GetEPrice',
    PHOTO_REQUEST = 'PhotoRequest',
    SCHEDULE_APPOINTMENT = 'ScheduleAppointment',
    SEND_TO_PHONE = 'SendToPhone',
    VEHICLE_INCENTIVE = 'VehicleIncentive',
}

export interface LeadModalOpenParams {
    vehicle?: Vehicle
    location?: Location
}
