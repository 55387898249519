import facetIconSchema from '~/utils/schemas/facetIconSchema'
import { FacetSchemaItemType } from '~/utils/types/schema/enums'

export const useFacetSchema = (
    schemaKey: FacetSchemaItemType,
    key: string,
): any => {
    const schemaItem = facetIconSchema[schemaKey] ?? null
    return schemaItem ? (schemaItem[key] ?? null) : null
}
