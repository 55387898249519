<template>
    <div
        :class="[
            'scroll-ms-6 snap-start flex-shrink-0 inline-flex items-center rounded-md bg-white px-2 py-1 text-xs font-medium text-gray-700 ring-1 ring-inset ring-gray-700/10',
            customClasses ?? '',
        ]"
    >
        <component
            :is="icon.component"
            v-if="icon"
            :class="['mr-0.5 w-4 h-4', icon.classes]"
        />
        <span v-html="content" />
    </div>
</template>

<script setup lang="ts">
import { useFacetSchema } from '~/composables/facet/useFacetSchema'
import { FacetSchemaItemType } from '~/utils/types/schema/enums'
import type { FeaturedOptionSchemaItem } from '~/utils/types/schema'

const props = defineProps({
    content: {
        type: String,
        required: true,
    },
    customClasses: {
        type: String,
        default: null,
    },
})

const formattedContent = computed(() =>
    props.content
        .replace('<em>', '')
        .replace('</em>', '')
        .replace('&nbsp;', ' '),
)

const icon = computed(() => {
    const iconCheck: FeaturedOptionSchemaItem | null =
        useFacetSchema(
            FacetSchemaItemType.FEATURED_OPTIONS,
            formattedContent.value ?? '',
        ) ?? null

    return iconCheck
        ? {
              component: defineAsyncComponent(
                  () => import(`~/assets/icons/features/${iconCheck.file}.svg`),
              ),
              classes: iconCheck.classes,
          }
        : null
})
</script>
