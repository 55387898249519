import { defineStore } from 'pinia'
import type { Vehicle, VehicleIncentive } from '~/utils/types/inventory'
import type { VehicleUnlockType } from '~/utils/types/vehicleUnlock/enums'
import { useFetchDealerMe } from '~/composables/fetch/useFetchDealerMe'

export const useVehicleUnlockStore = defineStore('vehicleUnlock', {
    state: (): State => ({
        currentStep: null,
        type: null,
        vehicle: null,
        incentive: null,
        customCreateFormButtonText: null,
        modalOpen: false,
    }),
    actions: {
        async createUnlockCode(vehicle: Vehicle, body: Record<string, any>) {
            return await useFetchDealerMe(`/inventory/${vehicle.id}/unlock`, {
                method: 'post',
                body,
                fetch: true,
            })
        },
        async sendConfirmCode(vehicle: Vehicle, body: Record<string, any>) {
            return await useFetchDealerMe(
                `/inventory/${vehicle.id}/unlock/confirm`,
                {
                    method: 'post',
                    body,
                    fetch: true,
                },
            )
        },
        openModal(
            type: VehicleUnlockType,
            vehicle: Vehicle,
            incentive: VehicleIncentive | null = null,
            customButtonText: string | null = null,
        ) {
            this.modalOpen = true
            this.type = type
            this.vehicle = vehicle
            this.incentive = incentive
            this.customCreateFormButtonText = customButtonText
        },
        closeModal() {
            this.modalOpen = false
            this.type = null
            this.vehicle = null
            this.incentive = null
            this.customCreateFormButtonText = null
        },
        updateStep(step: 'create' | 'verify') {
            this.currentStep = step
        },
        resetCurrentStep() {
            this.currentStep = null
        },
    },
})

interface State {
    currentStep: 'create' | 'verify' | null
    type: VehicleUnlockType | null
    vehicle: Vehicle | null
    incentive: VehicleIncentive | null
    customCreateFormButtonText: string | null
    modalOpen: boolean
}
